/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
const segmentAnonymousId = () => {
  if (window.analytics) {
    return window.analytics.user().anonymousId();
  }
  return undefined;
};

const segmentUserId = () => {
  if (window.analytics) {
    return window.analytics.user().id();
  }
  return undefined;
};

const initSegment = () => {
  window.analytics = window.analytics || [];
  const { analytics } = window;
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.13.1';
      analytics.load(process.env.GATSBY_SEGMENT_SECRET_KEY);
      // analytics.page({path: window.location.pathname, url: window.location.href,});
    }
  }
};

const segmentAuth = ({ id, data }) => {
  if (window.analytics) {
    if (id) {
      window.analytics.identify(id, data);
    } else {
      window.analytics.identify(data);
    }
  }
};

const segmentTrack = (eventName, data) => {
  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

function segmentPage(pageName, params) {
  window.analytics.page(params);
  segmentTrack('Page Viewed', { page_name: pageName, ...params });
}

const segmentSetExperimentCustomProperty = ({ flagValue, customPropertyName, conditions = true }) => {
  if (typeof flagValue === 'boolean' && conditions) {
    segmentAuth({
      data: {
        [customPropertyName]: flagValue,
      },
    });
  }
};

export {
  initSegment,
  segmentAuth,
  segmentTrack,
  segmentPage,
  segmentAnonymousId,
  segmentUserId,
  segmentSetExperimentCustomProperty,
};
