exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-deposito-bajo-monto-jsx": () => import("./../../../src/pages/deposito-bajo-monto.jsx" /* webpackChunkName: "component---src-pages-deposito-bajo-monto-jsx" */),
  "component---src-pages-deposito-ordinario-jsx": () => import("./../../../src/pages/deposito-ordinario.jsx" /* webpackChunkName: "component---src-pages-deposito-ordinario-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-adquirencia-jsx": () => import("./../../../src/pages/legal/adquirencia.jsx" /* webpackChunkName: "component---src-pages-legal-adquirencia-jsx" */),
  "component---src-pages-legal-aviso-de-privacidad-jsx": () => import("./../../../src/pages/legal/aviso-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-legal-aviso-de-privacidad-jsx" */),
  "component---src-pages-legal-certificacion-sarlaft-jsx": () => import("./../../../src/pages/legal/certificacion-sarlaft.jsx" /* webpackChunkName: "component---src-pages-legal-certificacion-sarlaft-jsx" */),
  "component---src-pages-legal-codigo-de-etica-y-conducta-bold-cf-jsx": () => import("./../../../src/pages/legal/codigo-de-etica-y-conducta-bold-cf.jsx" /* webpackChunkName: "component---src-pages-legal-codigo-de-etica-y-conducta-bold-cf-jsx" */),
  "component---src-pages-legal-corresponsal-bold-cf-jsx": () => import("./../../../src/pages/legal/corresponsal-bold-cf.jsx" /* webpackChunkName: "component---src-pages-legal-corresponsal-bold-cf-jsx" */),
  "component---src-pages-legal-derechos-del-consumidor-financiero-jsx": () => import("./../../../src/pages/legal/derechos-del-consumidor-financiero.jsx" /* webpackChunkName: "component---src-pages-legal-derechos-del-consumidor-financiero-jsx" */),
  "component---src-pages-legal-estados-financieros-autorizados-2023-jsx": () => import("./../../../src/pages/legal/estados-financieros-autorizados-2023.jsx" /* webpackChunkName: "component---src-pages-legal-estados-financieros-autorizados-2023-jsx" */),
  "component---src-pages-legal-index-jsx": () => import("./../../../src/pages/legal/index.jsx" /* webpackChunkName: "component---src-pages-legal-index-jsx" */),
  "component---src-pages-legal-informacion-del-defensor-del-consumidor-financiero-jsx": () => import("./../../../src/pages/legal/informacion-del-defensor-del-consumidor-financiero.jsx" /* webpackChunkName: "component---src-pages-legal-informacion-del-defensor-del-consumidor-financiero-jsx" */),
  "component---src-pages-legal-obligaciones-de-bold-cf-jsx": () => import("./../../../src/pages/legal/obligaciones-de-bold-cf.jsx" /* webpackChunkName: "component---src-pages-legal-obligaciones-de-bold-cf-jsx" */),
  "component---src-pages-legal-obligaciones-del-consumidor-financiero-jsx": () => import("./../../../src/pages/legal/obligaciones-del-consumidor-financiero.jsx" /* webpackChunkName: "component---src-pages-legal-obligaciones-del-consumidor-financiero-jsx" */),
  "component---src-pages-legal-politica-de-cookies-jsx": () => import("./../../../src/pages/legal/politica-de-cookies.jsx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-jsx" */),
  "component---src-pages-legal-politica-de-privacidad-jsx": () => import("./../../../src/pages/legal/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-jsx" */),
  "component---src-pages-legal-politica-de-reversion-del-pago-jsx": () => import("./../../../src/pages/legal/politica-de-reversion-del-pago.jsx" /* webpackChunkName: "component---src-pages-legal-politica-de-reversion-del-pago-jsx" */),
  "component---src-pages-legal-politicas-sarlaft-jsx": () => import("./../../../src/pages/legal/politicas-sarlaft.jsx" /* webpackChunkName: "component---src-pages-legal-politicas-sarlaft-jsx" */),
  "component---src-pages-legal-reglamento-de-deposito-jsx": () => import("./../../../src/pages/legal/reglamento-de-deposito.jsx" /* webpackChunkName: "component---src-pages-legal-reglamento-de-deposito-jsx" */),
  "component---src-pages-legal-terminos-y-condiciones-codigo-qr-cuenta-bold-jsx": () => import("./../../../src/pages/legal/terminos-y-condiciones-codigo-qr-cuenta-bold.jsx" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-codigo-qr-cuenta-bold-jsx" */),
  "component---src-pages-whatsapp-jsx": () => import("./../../../src/pages/whatsapp.jsx" /* webpackChunkName: "component---src-pages-whatsapp-jsx" */),
  "slice---src-components-footer-index-jsx": () => import("./../../../src/components/Footer/index.jsx" /* webpackChunkName: "slice---src-components-footer-index-jsx" */),
  "slice---src-components-navbar-index-jsx": () => import("./../../../src/components/Navbar/index.jsx" /* webpackChunkName: "slice---src-components-navbar-index-jsx" */)
}

