require('./src/styles/main.scss');
const { defineCustomElements } = require('@bold/web-components/loader');
const { initSegment, segmentPage } = require('./src/vendors/segment');

defineCustomElements();

exports.onClientEntry = () => {
  initSegment();
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const isDifferentPage = location.pathname !== prevLocation?.pathname || null;

  if (isDifferentPage && window.analytics) {
    segmentPage(location.pathname === '/' ? 'home' : location.pathname, {
      path: location.pathname,
      url: location.href,
    });
  }
};
